/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-highlight {
  --ion-color-base: var(--ion-color-highlight);
  --ion-color-base-rgb: var(--ion-color-highlight-rgb);
  --ion-color-contrast: var(--ion-color-highlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-highlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-highlight-shade);
  --ion-color-tint: var(--ion-color-highlight-tint);
}

.app-menu-modal, .app-menu-modal.show-modal {
    --ion-background-color: transparent;
    
    .modal-wrapper {
        --ion-background-color: transparent;    
    }

    ion-backdrop {
        background: var(--ion-color-tertiary);
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.95);
    }
}

.dialog-media {
    .modal-wrapper {
        width: 100vw;
        height: 100vh;
    }
}

.dialog-video {
    .modal-wrapper {
        width: 100vw;
        height: 100vh;
    }
}

.dialog-create-tag {
    .modal-wrapper {
        min-height: 100vh;
    }
}

.alert-location-permission {
    .alert-wrapper {
        max-width: 50vw;
    }

    ion-backdrop {
        --backdrop-opacity: 0.9;
    }
}
